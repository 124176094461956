import { fetchCurrentUser } from '../../../actions/auth';
import { store } from '../../../index';

export function* onDeleteTeam() {
  const { dispatch } = store;

  dispatch(fetchCurrentUser());

  yield true;
}
