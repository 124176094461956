import { store } from '../../../index';
import { selectedTeamStorage } from '../../../../helpers/storageFunctions';
import { PayloadAction } from '@reduxjs/toolkit';
import { fetchCurrentUser } from 'redux/actions/auth';
import { ITeams } from '../../../../types/api/teams';

export function* onChangeEmployeeStatus(action: PayloadAction<ITeams>) {
  const { dispatch } = store;

  dispatch(fetchCurrentUser());
  selectedTeamStorage().setSelectedTeam(action.payload);

  yield true;
}
