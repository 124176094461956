import { CurrentUser } from 'types/api/users';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectedTeamStorage } from 'helpers/storageFunctions';

function* onGetCurrentUser(action: PayloadAction<CurrentUser>) {
  const currentUser = action.payload;
  const teamInLocalStorage = selectedTeamStorage().getSelectedTeam();

  if (!teamInLocalStorage && currentUser.teams.length) {
    selectedTeamStorage().setSelectedTeam(currentUser.teams[0]);
  }

  if (teamInLocalStorage && !currentUser.teams.length) {
    selectedTeamStorage().setSelectedTeam(null);
  }

  yield true;
}

export default onGetCurrentUser;
