import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { LOADING } from '../../assets/loading';
import {
  GET_ALL_CANDIDATES_SUCCESS,
  GET_ALL_CANDIDATES_PENDING,
  GET_ALL_CANDIDATES_FAILURE,
  GET_CANDIDATE_BY_ID_SUCCESS,
  CLEAR_SELECTED_CANDIDATE,
} from '../actions/candidates';
import { CHANGE_CANDIDATES_LIST_FILTERS } from '../actions/filters/candidatesFilter';
import { TableFilter } from './teams';
import { ICandidates, IGetAllCandidates } from '../../types/api/candidates';

type InitialState = {
  selectedCandidate: ICandidates | null;
  candidates: ICandidates[];
  offset: number | null;
  totalLength: number | null;
  page: number | null;
  loading: string | null;
};

const initialState: InitialState = {
  selectedCandidate: null,
  candidates: [],
  page: null,
  offset: null,
  totalLength: null,
  loading: null,
};

const handlers = {
  [GET_ALL_CANDIDATES_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<IGetAllCandidates>) => {
    return {
      ...prevState,
      candidates: payload.candidates,
      page: payload.page,
      totalLength: payload.totalLength,
      loading: LOADING.SUCCESS,
    };
  },
  [GET_ALL_CANDIDATES_PENDING.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loading: LOADING.PENDING,
    };
  },
  [GET_ALL_CANDIDATES_FAILURE.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loading: LOADING.FAILURE,
    };
  },
  [GET_CANDIDATE_BY_ID_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<ICandidates>) => {
    return {
      ...prevState,
      selectedCandidate: payload,
    };
  },
  [CHANGE_CANDIDATES_LIST_FILTERS.type]: (prevState: InitialState, { payload }: PayloadAction<TableFilter[]>) => {
    return {
      ...prevState,
      filters: payload,
    };
  },
  [CLEAR_SELECTED_CANDIDATE.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      selectedCandidate: null,
    };
  },
};

export default createReducer(initialState, handlers);
