import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Avatar, Divider, Typography } from '@mui/material';

import InviteUsersModal from 'components/modal/invite-users-modal';
import { RolePermissions } from '../../../helpers/RolePermissions';
import { prevPageUrlStorage } from 'helpers/storageFunctions';
import { IEmployees, ITeams } from 'types/api/teams';
import { CurrentUser } from 'types/api/users';

import useStyles from './style';

type TeamMenuProps = {
  currentUser: CurrentUser;
  teamName: string;
  closePopover?: (event: React.MouseEvent<any>) => void;
};

const TeamMenu: React.FC<TeamMenuProps> = ({ currentUser, teamName, closePopover }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const shortTeamName = teamName?.split('', 1).join('');
  const activeTeam = currentUser?.teams.find((team: ITeams) => team.name === teamName);
  const currentUserTeamRole =
    activeTeam?.employees.find((employee: IEmployees) => employee._id === currentUser._id)?.role ?? '';

  return (
    <>
      <div className={classes.section}>
        <div className={classes.info}>
          <Avatar alt='avatar' className={classes.avatar}>
            {shortTeamName}
          </Avatar>
          <Typography className={classes.teamName}>{teamName}</Typography>
        </div>
        <Divider className={classes.divider} />
        <nav>
          <Link to='/team/profile'>
            <Typography
              className={classes.navLink}
              onClick={(event) => {
                closePopover?.(event);
                prevPageUrlStorage().setPrevPageUrl([]);
              }}
            >
              {t('teamProfileLinkText')}
            </Typography>
          </Link>
          {RolePermissions.canEditTeam(currentUserTeamRole) ? (
            <>
              <Typography
                className={classes.navLink}
                onClick={() => {
                  setIsOpen(true);
                  setIsBtnDisabled(false);
                }}
              >
                {t('teamInvitePeopleText')}
              </Typography>
              <Link to='#'>
                <Typography
                  className={classes.disabledNavLink}
                  onClick={(event) => {
                    closePopover?.(event);
                    prevPageUrlStorage().setPrevPageUrl([]);
                  }}
                >
                  {t('teamSecurityAndPermissionsText')}
                </Typography>
              </Link>
            </>
          ) : (
            <>
              <Typography className={classes.disabledNavLink}>{t('teamInvitePeopleText')}</Typography>
              <Typography className={classes.disabledNavLink}>{t('teamSecurityAndPermissionsText')}</Typography>
            </>
          )}
        </nav>
      </div>
      <InviteUsersModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isBtnDisabled={isBtnDisabled}
        setIsBtnDisabled={setIsBtnDisabled}
      />
    </>
  );
};

export default TeamMenu;
