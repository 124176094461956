import i18next from 'i18next';
import { format, getMonth, isThisWeek, startOfWeek, endOfWeek, parseISO, getISODay, addDays } from 'date-fns';
import { enUS, uk } from 'date-fns/locale';

export const localeMonthsLabels = (): { [key: string]: string } => ({
  Jan: i18next.t('JanMonth'),
  Feb: i18next.t('FebMonth'),
  Mar: i18next.t('MarMonth'),
  Apr: i18next.t('AprMonth'),
  May: i18next.t('MayMonth'),
  Jun: i18next.t('JunMonth'),
  Jul: i18next.t('JulMonth'),
  Aug: i18next.t('AugMonth'),
  Sep: i18next.t('SepMonth'),
  Oct: i18next.t('OctMonth'),
  Nov: i18next.t('NovMonth'),
  Dec: i18next.t('DecMonth'),
});

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

enUS.options!.weekStartsOn = 1;

export type localeObjType = {
  uk: Locale;
  en: Locale;
};

export const localeObj: localeObjType = {
  uk: uk,
  en: enUS,
};

export function formatToLocaleLabel(strDate: string) {
  const [day, month] = strDate.split(' ');
  const localeMonth = localeMonthsLabels()[month];

  return `${day} ${localeMonth}`;
}

export const formatAndLocalizeDate = (date: Date, formatStr = 'dd MMM yyyy') => {
  if (formatStr?.includes('MMM')) {
    const monthIndex = formatStr?.split(' ').findIndex((elem) => elem === 'MMM');
    const formattedDate = format(date, formatStr, { locale: localeObj[i18next.language as keyof localeObjType] });
    const targetMonth = getMonth(date);
    return formattedDate
      ?.split(' ')
      .map((item, index) => (index === monthIndex ? localeMonthsLabels()[months[targetMonth]] : item))
      .join(' ');
  }
  return format(date, formatStr, { locale: localeObj[i18next.language as keyof localeObjType] });
};

export const getNounYear = (number: number, one: string, two: string, five: string) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return `${number} ${five}`;
  }
  n %= 10;
  if (n === 1) {
    return `${number} ${one}`;
  }
  if (n >= 2 && n <= 4) {
    return `${number} ${two}`;
  }
  return `${number} ${five}`;
};

export const getNoun = (number: number) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return 'днів';
  }
  n %= 10;
  if (n === 1) {
    return 'день';
  }
  if (n >= 2 && n <= 4) {
    return 'дні';
  }
  return 'днів';
};

export const thisWeekAbsence = (absences: { startDate: string; endDate: string }[]) => {
  const thisWeekAbs = absences.filter(
    (item) =>
      isThisWeek(new Date(item?.startDate), { weekStartsOn: 1 }) ||
      isThisWeek(new Date(item?.endDate), { weekStartsOn: 1 }),
  );

  const longAbs = absences.filter(
    (item) =>
      parseISO(item?.startDate) < startOfWeek(new Date(), { weekStartsOn: 1 }) &&
      parseISO(item?.endDate) > endOfWeek(new Date(), { weekStartsOn: 1 }),
  );

  return [...thisWeekAbs, ...longAbs].sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
};

export const getCurrentWeekMonday = () => {
  const fromDate = new Date(); //present date
  const dayOfWeek = format(new Date(), 'eee'); //present day of week
  const dayOfWeekMap: { [key: string]: number } = {
    //list of days of the week, the item of which we must subtract from today to getting Mondays
    Mon: 1,
    Tue: 3,
    Wed: 5,
    Thu: 7,
    Fri: 9,
    Sat: 11,
    Sun: 13,
  };
  const offsetDays = getISODay(fromDate) - dayOfWeekMap[dayOfWeek]; //getting quantity of offset days to getting monday
  return addDays(fromDate, offsetDays);
};

export const getTimeFromDate = (date: Date) => {
  return format(date, 'HH:mm');
};

export const convertTimestampsToDate = (date: string) => {
  return new Date(date).getTime();
};
