import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { LOADING } from 'assets/loading';
import { CHANGE_TEAM_FILTERS } from 'redux/actions/filters/teamFilters';
import { GET_TEAM_FAILURE, GET_TEAM_PENDING, GET_TEAM_SUCCESS, CLEAR_SELECTED_TEAM } from 'redux/actions/teams';
import { IUser } from 'types/api/users';

export type ActiveTeamEmployeeInfo = Pick<
  IUser,
  | '_id'
  | 'firstNameLatin'
  | 'lastNameLatin'
  | 'firstNameLocal'
  | 'lastNameLocal'
  | 'email'
  | 'currentAvatar'
  | 'mobilePhone'
  | 'country'
  | 'city'
  | 'birthday'
  | 'updatedAt'
>;

export type ActiveTeamEmployee = {
  _id: ActiveTeamEmployeeInfo;
  role: 'COMPANY_ADMIN' | 'COMPANY_OWNER' | 'USER';
  status: 'ACTIVE' | 'PENDING' | 'ARCHIVED';
  invitedsEmail: string;
};

export type Member = {
  invitedsEmail: string;
  role: string;
  status: string;
  startDate?: Date;
};

export type ActiveTeam = {
  _id: string;
  name: string;
  employees: ActiveTeamEmployee[];
  member?: Member[];
  plan: string;
};

export type TableFilter = {
  name: string;
  direction: 'up' | 'down' | null;
};

type InitialState = {
  activeTeam: ActiveTeam | null;
  loading: string | null;
  filters: TableFilter[];
};

const initialState: InitialState = {
  activeTeam: null,
  loading: null,
  filters: [
    {
      name: 'name',
      direction: 'up',
    },
    {
      name: 'email',
      direction: null,
    },
    {
      name: 'role',
      direction: null,
    },
  ],
};

const handlers = {
  [GET_TEAM_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<ActiveTeam>) => {
    return {
      ...prevState,
      activeTeam: payload,
      loading: LOADING.SUCCESS,
    };
  },
  [GET_TEAM_PENDING.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loading: LOADING.PENDING,
    };
  },
  [GET_TEAM_FAILURE.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loading: LOADING.FAILURE,
    };
  },
  [CHANGE_TEAM_FILTERS.type]: (prevState: InitialState, { payload }: PayloadAction<TableFilter[]>) => {
    return {
      ...prevState,
      filters: payload,
    };
  },
  [CLEAR_SELECTED_TEAM.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      activeTeam: null,
    };
  },
};

export default createReducer(initialState, handlers);
