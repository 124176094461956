import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import {
  GET_REMINDERS_SUCCESS,
  GET_TODAY_REMINDERS_STATUS_SUCCESS,
  GET_CANDIDATE_REMINDERS_SUCCESS,
  CLEAR_CANDIDATE_REMINDERS,
  GET_USER_REMINDERS_SUCCESS,
  CREATE_REMINDER_SUCCESS,
} from '../actions/candidateReminders';
import { ICandidateReminder } from 'types/api/candidates';
import { LOADING } from '../../assets/loading';
import { calculateDaysDifferenceFromNow } from 'helpers/calculateDaysDifference';
import { REMINDER_LIMIT } from 'shared/notifications-popover/constants';
import { IRemindersActions } from '../../types/filters/reminders';
import { IRemindersFilters } from '../../components/reminders-section/configFilter';
import { CHANGE_REMINDERS_FILTERS } from '../actions/filters/remindersFilters';

type InitialState = {
  reminders: ICandidateReminder[];
  userReminders: ICandidateReminder[];
  candidateReminders: ICandidateReminder[];
  page: number | null;
  totalLength: number | null;
  todayRemindersStatus: boolean;
  loading: string | null;
  filters: IRemindersFilters;
};

const initialState: InitialState = {
  reminders: [],
  userReminders: [],
  candidateReminders: [],
  page: null,
  totalLength: null,
  todayRemindersStatus: false,
  loading: null,
  filters: {
    searchByName: '',
    createdBy: '',
    page: '1',
    offset: '10',
    column: {
      name: 'date',
      direction: 'up',
    },
  },
};

const handlers = {
  [GET_REMINDERS_SUCCESS.type]: (
    prevState: InitialState,
    { payload }: PayloadAction<{ reminders: ICandidateReminder[]; page: number; totalLength: number }>,
  ) => {
    return {
      ...prevState,
      reminders: payload?.reminders,
      page: payload?.page,
      totalLength: payload?.totalLength,
      loading: LOADING.SUCCESS,
    };
  },

  [GET_USER_REMINDERS_SUCCESS.type]: (
    prevState: InitialState,
    { payload }: PayloadAction<{ reminders: ICandidateReminder[] }>,
  ) => {
    return {
      ...prevState,
      userReminders: payload?.reminders,
      loading: LOADING.SUCCESS,
    };
  },

  [GET_CANDIDATE_REMINDERS_SUCCESS.type]: (
    prevState: InitialState,
    { payload }: PayloadAction<ICandidateReminder[]>,
  ) => {
    return {
      ...prevState,
      candidateReminders: payload,
      loading: LOADING.SUCCESS,
    };
  },

  [GET_TODAY_REMINDERS_STATUS_SUCCESS.type]: (
    prevState: InitialState,
    { payload }: PayloadAction<{ todayRemindersStatus: boolean }>,
  ) => {
    return {
      ...prevState,
      todayRemindersStatus: payload?.todayRemindersStatus,
      loading: LOADING.SUCCESS,
    };
  },

  [CLEAR_CANDIDATE_REMINDERS.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      reminders: [],
    };
  },
  [CREATE_REMINDER_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<ICandidateReminder>) => {
    return {
      ...prevState,
      reminders: [payload, ...prevState.reminders],
      userReminders:
        calculateDaysDifferenceFromNow(payload?.date) <= REMINDER_LIMIT.DAYS_TO_REMIND
          ? [...prevState.userReminders, payload].sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            )
          : prevState.userReminders,
      totalLength: prevState.totalLength ? prevState.totalLength + 1 : 1,
    };
  },
  [CHANGE_REMINDERS_FILTERS.type]: (prevState: InitialState, { payload }: PayloadAction<IRemindersActions>) => {
    return {
      ...prevState,
      filters: payload,
    };
  },
};

export default createReducer(initialState, handlers);
