import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { REFERS_TO_ENUM } from '../../assets/refersTo';

type GetAllChannelsActionType = {
  refersTo?: string;
  onFailure?: (error: string) => void;
};

export const GET_ALL_CHANNELS_SUCCESS = createAction('channel/GET_ALL_CHANNELS.SUCCESS');
export const getAllChannels =
  ({ refersTo, onFailure }: GetAllChannelsActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: 'candidate-channel',
        qsParams: {
          refersTo: refersTo ?? null,
        },
      });
      dispatch(GET_ALL_CHANNELS_SUCCESS(data));
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      onFailure?.(errorMessage);
    }
  };

type CreateChannelActionType = {
  channelData: {
    name: string;
    refersTo: REFERS_TO_ENUM;
  }[];
  onSuccess: () => void;
  onFailure: (error: string) => void;
};

export const CREATE_CHANNEL_SUCCESS = createAction('channel/CREATE_CHANNEL.SUCCESS');
export const createChannel =
  ({ channelData, onSuccess, onFailure }: CreateChannelActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `candidate-channel`,
        query: { channelData },
      });
      dispatch(CREATE_CHANNEL_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      onFailure(errorMessage);
    }
  };

type UpdateChannelActionType = {
  channelId: string;
  channelData: {
    name: string;
    refersTo: REFERS_TO_ENUM;
  };
  onSuccess: () => void;
  onFailure: (error: string) => void;
};

export const UPDATE_CHANNEL_SUCCESS = createAction('channel/UPDATE_CHANNEL.SUCCESS');
export const updateChannel =
  ({ channelId, channelData, onSuccess, onFailure }: UpdateChannelActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `candidate-channel/${channelId}`,
        query: channelData,
      });
      dispatch(UPDATE_CHANNEL_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      onFailure(errorMessage);
    }
  };

type DeleteChannelActionType = {
  channelId: string;
  onSuccess: () => void;
  onFailure: (error: string) => void;
};

export const DELETE_CHANNEL_SUCCESS = createAction('channel/DELETE_CHANNEL.SUCCESS');
export const deleteChannel =
  ({ channelId, onSuccess, onFailure }: DeleteChannelActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `candidate-channel/${channelId}`,
      });
      dispatch(DELETE_CHANNEL_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      onFailure(errorMessage);
    }
  };
