import { PayloadAction } from '@reduxjs/toolkit';
import { selectedTeamStorage } from 'helpers/storageFunctions';
import { getTeamById } from 'redux/actions/teams';
import { ITeams } from 'types/api/teams';
import { store } from '../../../index';

export function* onUpdateDicisionMakers(action: PayloadAction<ITeams>) {
  const { dispatch } = store;

  dispatch(getTeamById({}));
  selectedTeamStorage().setSelectedTeam(action.payload);

  yield true;
}
