import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { ApiService } from '../middleware/api';

export const GENERATE_ONETIME_LINK_SUCCESS = createAction('reset/GENERATE_ONETIME_LINK.SUCCESS');
export const GENERATE_ONETIME_LINK_FAILURE = createAction<string>('reset/GENERATE_ONETIME_LINK.FAILURE');
export const generateOnetimeLink =
  (email: string, handleResetPassword: () => void) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `reset/one-time-link/generate`,
        query: { email },
      });
      dispatch(GENERATE_ONETIME_LINK_SUCCESS(data));
      handleResetPassword();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch(GENERATE_ONETIME_LINK_FAILURE(errorMessage));
      handleResetPassword();
    }
  };

export const VALIDATE_ONE_TIME_LINK_SUCCESS = createAction('reset/VALIDATE_ONE_TIME_LINK.SUCCESS');
export const VALIDATE_ONE_TIME_LINK_FAILURE = createAction<string>('reset/VALIDATE_ONE_TIME_LINK.FAILURE');
export const validateOneTimeLink =
  (token: string, onFailure: (error: string) => void, invite?: boolean) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `reset/one-time-link/validate?invite=${invite ? invite : false}`,
        query: { token },
      });
      dispatch(VALIDATE_ONE_TIME_LINK_SUCCESS(data));
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      VALIDATE_ONE_TIME_LINK_FAILURE(errorMessage);
      onFailure(errorMessage);
    }
  };

export const RESET_PASSWORD_BY_EMAIL_SUCCESS = createAction('reset/RESET_PASSWORD_BY_EMAIL.SUCCESS');
export const RESET_PASSWORD_BY_EMAIL_FAILURE = createAction<string>('reset/RESET_PASSWORD_BY_EMAIL.FAILURE');
export const resetPasswordByEmail =
  (email: string, password: string, onSuccess: () => void, onFailure: (error: string) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: 'reset/reset-password',
        query: { email, password },
      });
      dispatch(RESET_PASSWORD_BY_EMAIL_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      dispatch(RESET_PASSWORD_BY_EMAIL_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };
