import { fetchCurrentUser } from 'redux/actions/auth';
import { store } from '../../../index';
import { selectedTeamStorage } from 'helpers/storageFunctions';
import { PayloadAction } from '@reduxjs/toolkit';
import { ITeams } from 'types/api/teams';

export function* onInviteUsers(action: PayloadAction<ITeams>) {
  const { dispatch } = store;

  dispatch(fetchCurrentUser());
  selectedTeamStorage().setSelectedTeam(action.payload);

  yield true;
}
