import { Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ProfilesNavigation from '../../../../components/navigations-section/profiles-navigaton-popover';

import { languageStore, prevPageUrlStorage } from '../../../../helpers/storageFunctions';
import { ReactComponent as OmiteamLogo } from '../../../../svg/authorizationSection/omi-team.svg';
import { ReactComponent as GbFlagIcon } from '../../../../svg/sidebar/gb-flag.svg';
import { ReactComponent as UaFlagIcon } from '../../../../svg/sidebar/ua-flag.svg';

import { RoutesConstants } from 'constants/routesConstants';
import useStyles from './style';

type SidebarContainerProps = {
  routesWithAccess: JSX.Element;
  isSidebarOpen?: boolean;
  isMobileSidebar?: boolean;
};

const SidebarContainer: React.FC<SidebarContainerProps> = ({ routesWithAccess, isSidebarOpen, isMobileSidebar }) => {
  let open = Boolean(isSidebarOpen);
  const mobile = Boolean(isMobileSidebar);
  const { classes } = useStyles({ open, mobile });
  const [lang, setLang] = useState(localStorage.getItem('language') || 'en');
  const { i18n } = useTranslation();

  const changeLang = (lang: string) => {
    setLang(lang);
    i18n.changeLanguage(lang);
    languageStore().setLanguage(lang);
  };

  useEffect(() => {
    changeLang(lang);
  }, [lang]);

  return (
    <div className={classes.sidebarContainer}>
      <div className={classes.navLinksContainer}>
        <div
          className={classes.logoContainer}
          onClick={() => {
            prevPageUrlStorage().setPrevPageUrl([]);
          }}
        >
          <Link to={RoutesConstants.HOMEPAGE}>
            {open ? <OmiteamLogo className={classes.logo} /> : <div className={classes.logoSmall}>OT</div>}
          </Link>
        </div>
        {routesWithAccess}
      </div>
      <div>
        <ProfilesNavigation isSidebarOpen={open} mobile={mobile} />
        <div className={classes.sidebarFooter}>
          <Typography className={classes.sidebarAppVersion}>v0.1 beta</Typography>
          <div className={classes.langContainer}>
            <Button
              onClick={() => changeLang('uk')}
              className={lang === 'uk' ? `${classes.langBtn} ${classes.langPicked}` : classes.langBtn}
            >
              <UaFlagIcon className={classes.flagIcon} />
              <Typography className={`${classes.langBtnText} ${lang === 'uk' && classes.langPickedBtnText}`}>
                Ukr
              </Typography>
            </Button>
            <Button
              onClick={() => changeLang('en')}
              className={lang === 'en' ? `${classes.langBtn} ${classes.langPicked}` : classes.langBtn}
              style={{ marginLeft: open ? '8px' : '0' }}
            >
              <GbFlagIcon className={classes.flagIcon} />
              <Typography className={`${classes.langBtnText} ${lang === 'en' && classes.langPickedBtnText}`}>
                Eng
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarContainer;
