import { createReducer } from '@reduxjs/toolkit';

import { CHANGE_TEAM_FILTERS } from '../actions/filters/teamFilters';
import { CHANGE_PROJECT_FILTERS, RESET_PROJECT_FILTERS } from '../actions/filters/projectFilters';
import { CHANGE_TEAM_SEARCH_FILTERS } from '../actions/filters/reportsFilters';

import { projectFiltersStorage } from 'helpers/storageFunctions';

const initialState = {
  teamFilters: null,
  projectFilters: projectFiltersStorage().getProjectFilter(),
  teamSearchFilter: [],
};

const handlers = {
  [CHANGE_TEAM_FILTERS]: (prevState, { payload }) => {
    return {
      ...prevState,
      teamFilters: payload,
    };
  },
  [CHANGE_PROJECT_FILTERS]: (prevState, { payload }) => {
    projectFiltersStorage().setProjectFilter({ ...prevState.projectFilters, ...payload });
    return {
      ...prevState,
      projectFilters: { ...prevState.projectFilters, ...payload },
    };
  },
  [RESET_PROJECT_FILTERS]: (prevState) => {
    projectFiltersStorage().setProjectFilter({});
    return {
      ...prevState,
      projectFilters: {},
    };
  },
  [CHANGE_TEAM_SEARCH_FILTERS]: (prevState, { payload }) => {
    return {
      ...prevState,
      teamSearchFilter: payload,
    };
  },
};

export default createReducer(initialState, handlers);
