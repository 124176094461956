import { useEffect, useRef, useState } from 'react';

import theme from 'theme/theme';
import { Avatar, useMediaQuery } from '@mui/material';
import { ReactComponent as ArrowUpIcon } from '../../../svg/userProfileSection/arrow-up.svg';

import { horizontalScroll, verticalScroll } from 'helpers/scrollOnClick';
import { ITeams } from 'types/api/teams';

import useStyles from './style';

type UserTeamsSelectorProps = {
  userTeams: ITeams[];
  setActiveTeam: (team: ITeams) => void;
  activeTeamName: string;
};

const UserTeamsSelector: React.FC<UserTeamsSelectorProps> = ({ userTeams, setActiveTeam, activeTeamName }) => {
  const { classes } = useStyles();
  const teamsContainer = useRef<HTMLDivElement | null>(null);
  const mainContainer = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const [showLeftOrUpArrow, setShowLeftOrUpArrow] = useState(false);
  const [showRightOrDownArrow, setShowRightOrDownArrow] = useState(true);

  useEffect(() => {
    if (isMobile) {
      const mainContainerWidth = mainContainer.current!.offsetWidth;
      const teamsContainerWidth = teamsContainer.current!.scrollWidth;

      if (teamsContainerWidth <= mainContainerWidth) {
        setShowRightOrDownArrow(false);
      }
    } else {
      const mainContainerHeight = mainContainer.current!.offsetHeight;
      const teamsContainerHeight = teamsContainer.current!.scrollHeight;

      if (teamsContainerHeight <= mainContainerHeight) {
        setShowRightOrDownArrow(false);
      }
    }
  }, []);

  return (
    <div
      ref={mainContainer}
      className={`${classes.container} ${!userTeams?.length && classes.emptyContainer}`}
      style={showLeftOrUpArrow && showRightOrDownArrow ? { maxHeight: '150px' } : { maxHeight: '180px' }}
    >
      {showLeftOrUpArrow && (
        <div
          onClick={() => {
            isMobile
              ? horizontalScroll(teamsContainer.current, 25, 100, -10, setShowLeftOrUpArrow, setShowRightOrDownArrow)
              : verticalScroll(teamsContainer.current, 25, 110, -10, setShowLeftOrUpArrow, setShowRightOrDownArrow);
          }}
        >
          <ArrowUpIcon fill={'#E1E1E1'} width={12} height={12} className={classes.leftArrow} />
        </div>
      )}
      <div ref={teamsContainer} className={classes.avatarsContainer}>
        {userTeams?.map((userTeam: ITeams) => {
          const shortTeamName = userTeam.name?.split('', 1).join('');
          const isActive = activeTeamName === userTeam.name;

          return (
            <div key={userTeam._id} className={classes.teamItemContainer} onClick={() => setActiveTeam(userTeam)}>
              <Avatar alt='avatar' className={`${classes.avatar} ${isActive && classes.activeAvatar}`}>
                {shortTeamName}
              </Avatar>
            </div>
          );
        })}
      </div>
      {showRightOrDownArrow && (
        <div
          onClick={() => {
            isMobile
              ? horizontalScroll(teamsContainer.current, 25, 100, 10, setShowLeftOrUpArrow, setShowRightOrDownArrow)
              : verticalScroll(teamsContainer.current, 25, 110, 10, setShowLeftOrUpArrow, setShowRightOrDownArrow);
          }}
        >
          <ArrowUpIcon fill={'#E1E1E1'} width={12} height={12} className={classes.rightArrow} />
        </div>
      )}
    </div>
  );
};

export default UserTeamsSelector;
